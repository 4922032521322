.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper {
  display: flex;
  flex-flow: row nowrap;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper > .add-discount-field {
  flex: 1 1 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .switch-control {
  width: 54px;
  height: 34px;
  padding: 10px;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .switch-control input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .switch-control label {
  margin: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: var(--text-on-gray);
  display: block;
  border-radius: 7px;
  position: relative;
  transition: 0.3s;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .switch-control label:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: -3.5px;
  left: -3.5px;
  background: var(--bg-gray);
  border-radius: 50%;
  transition: 0.3s;
  box-shadow: var(--btn-shadow);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .switch-control input:checked + label {
  background: var(--blue-200);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .switch-control input:checked + label:after {
  background: var(--blue-500);
  left: calc(100% + 3.5px);
  transform: translateX(-100%);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .discount_by {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  color: var(--text-muted);
  background-color: var(--control-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .help-box {
  display: none;
}